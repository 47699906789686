@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

.accordion {
    padding: 2rem 0;

   .card {
       margin: 0 0 0.5rem;
       border: 1px solid $gray-200;
       padding: 0.5rem 1rem;

       @include media-breakpoint-up(lg) {
           padding: 1rem 1.25rem;
       }

       .card-header {
           position: relative;
           text-transform: uppercase;

           > .btn {
               display: block;
               width: 100%;
               padding: 0;
               border: 0;
               text-align: left;

               /*&:hover {
                   text-decoration: none;
               }*/

               &:focus {
                   box-shadow: none;
               }
           }

           .h5 {
               display: block;
               max-width: calc(100% - 30px);

               @include media-breakpoint-up(lg) {
                   max-width: calc(100% - 38px);
               }
           }

           .svg-icon {
               position: absolute;
               top: 50%;
               transform: translateY(-50%);
               right: 0;
               transition: opacity 0.2s;
               stroke-width: 4px;
               color: $secondary;
               font-size: 20px;

               @include media-breakpoint-up(lg) {
                   font-size: 28px;
               }
           }

           .icon-plus {
               opacity: 0;
           }

           > .collapsed {
               .icon-minus {
                   opacity: 0;
               }

               .icon-plus {
                   opacity: 1;
               }
           }
       }

       .card-body {
           padding: 1rem 0 0;
       }
   }
}
